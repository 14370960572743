import React, { FC } from 'react';

import DropdownCategories from 'gatsby-theme-dettol/src/components/DropdownCategories';
import CategoriesNavItem from 'gatsby-theme-dettol/src/components/common/CategoriesNav/CategoriesNavItem';

import { ICategoriesNav } from 'gatsby-theme-dettol/src/components/common/CategoriesNav/models';
import 'gatsby-theme-dettol/src/components/common/CategoriesNav/CategoriesNav.scss';
import './CategoriesNavOverride.scss';

const CategoriesNav: FC<ICategoriesNav> = ({ selectedTag, categories, btnDropdown }) => (
  <div className="dt-categories-nav">
    <div className="dt-categories-nav__desktop">
      <nav className="dt-categories-nav__list">
        <ul>
          {categories?.map(
            (tag) =>
              tag &&
              selectedTag && (
                <CategoriesNavItem key={tag.id} tag={tag} selected={tag.id === selectedTag.id} />
              )
          )}
        </ul>
      </nav>
    </div>

    <div className="dt-categories-nav__mobile">
      <DropdownCategories btn={btnDropdown} tags={categories} />
    </div>
  </div>
);

export default CategoriesNav;